import { useState } from 'react';
import Button from '@mui/material/Button';
import Step from "../Step";
import ZoneSelector from "./ZoneSelector";
import StepInformation from "../StepInformation";
import staticText from "../../../assets/data/staticText.json";
import zoneBackgroundImage from "../../../assets/img/stepBackgrounds/step4.jpg";
import { StepperFooter, useStepper } from '../../providers/StepperProvider';
import { useFilter } from '../../providers/FilterProvider';

export default function ZoneStep({repeatable}) {
  const [nextDisabled, setNextDisabled] = useState(true);
  const { setStepBack, setStepNext } = useStepper();
  const { filters } = useFilter();

  const redirect = !nextDisabled && !filters.zone;

  const zoneInfoPanel = (
    <StepInformation
      title={staticText.steps.zone.title}
      description={<p>{staticText.steps.zone.description}</p>}
    />
  );

  const zoneSelectionPanel = (
    <div
      style={{
        padding: "30px",
        height: "100%",
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ZoneSelector setNextDisabled={setNextDisabled} />
    </div>
  );

  return (
    <>
      <Step
        informationComponent={zoneInfoPanel}
        selectionComponent={zoneSelectionPanel}
        backgroundImage={zoneBackgroundImage}
      />
      <StepperFooter
        nextDisabled={nextDisabled}
        onNext={redirect ? setStepBack : setStepNext}
      />
    </>
  );
};
