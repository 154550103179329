import { useEffect, useState } from "react";

import Step from "../Step";
import PlantList from "./PlantList";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import PlantRepository from "../../../repository/PlantRepository";
import { Typography, Box } from "@mui/material";
import resultsBackgroundImage from "../../../assets/img/stepBackgrounds/step6.jpg";
import staticText from "../../../assets/data/staticText.json";
import { useFilter } from "../../providers/FilterProvider";
import { StepperFooter } from "../../providers/StepperProvider";

const RESULTS_DESCRIPTION = (
  <Typography>
    Please review the plant species list. It can be saved as a CSV file or
    appended to a planting plan guidebook. Additional information on each
    species can be obtained by entering the plant species name into the search
    box at{" "}
    <a href="https://inaturalist.nz" target="blank">
      inaturalist.nz
    </a>{" "}
    or in the "search flora" box at{" "}
    <a href="https://www.nzpcn.org.nz/" target="blank">
      www.nzpcn.org.nz
    </a>
    . Click the "Download PDF" button to create a planting plan guidebook
    specific to your project site.
  </Typography>
);

export default function ResultsStep(props) {
  const [plants, setPlants] = useState([]);
  const { filters } = useFilter();

  useEffect(() => {
    const updatePlants = () => {
      PlantRepository.getFilteredPlants(filters)
        .then((response) => {
          if (response.status === 200) {
            setPlants(response.data);
          }
        })
        .catch((e) => {
          this.setState({ plants: ["No plants found."] });
        });
    };
    updatePlants();
  }, [filters]);

  function createData(
    name,
    growthForm,
    moisturePreferences,
    plantTolerances,
    ecosystemServices,
    carbonSequestration,
    plantingStage
  ) {
    return {
      name,
      growthForm,
      moisturePreferences,
      plantTolerances,
      ecosystemServices,
      carbonSequestration,
      plantingStage,
    };
  }

  const getTableRows = () => {
    return plants.map((plant) => {
      return createData(
        plant.display_name,
        plant.display_growth_form,
        plant.moisture_preferences,
        plant.plant_tolerances,
        plant.ecosystem_services,
        plant.carbon_sequestration,
        plant.stage
      );
    });
  };

  const download = (response, fileType, fileName) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: fileType })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV = () => {
    PlantRepository.getPlantsCSV(filters).then((response) => {
      download(response, "text/csv", "plants.csv");
    });
  };

  const downloadPDF = () => {
    PlantRepository.getPlantsPDF(filters).then((response) => {
      download(response, "application/pdf", "planting_guide.pdf");
    });
  };

  const stepContent = (
    <div className="py-4">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="pb-4"
      >
        <Typography variant="h5">{staticText.steps.results.title}</Typography>
        <Stack spacing={2} direction="row" justifyContent="end">
          <Button variant="contained" onClick={() => downloadPDF()}>
            Download PDF
          </Button>
          <Button variant="contained" onClick={() => downloadCSV()}>
            Download CSV
          </Button>
        </Stack>
      </Box>
      <Typography
        variant="body2"
        sx={{ paddingRight: "100px", paddingBottom: "20px" }}
      >
        {RESULTS_DESCRIPTION}
      </Typography>
      <PlantList rows={getTableRows()} />
    </div>
  );

  return (
    <>
      <Step
        contentComponent={stepContent}
        backgroundImage={resultsBackgroundImage}
      />
      <StepperFooter />
    </>
  );
}
