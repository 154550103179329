import Repository from "./Repository";

const SiteRepository = {
  getHabitats() {
    return Repository.get(`/habitats/`);
  },

  getZones() {
    return Repository.get(`/zones/`);
  },

  getHabitatImage(habitatImageID) {
    return Repository.get(`/habitatimage/${habitatImageID}/`);
  },
};

export default SiteRepository;
