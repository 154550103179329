import API from "./Repository";

const LocationRepsostory = {
  getSoilDetails(filters) {
    return API.get(`/soil/`, { params: filters });
  },

  getEcologicalDistrictDetails(filters) {
    return API.get(`/ecologicaldistrict/`, { params: filters });
  },

  getRegionDetails(filters) {
    return API.get(`/region/`, { params: filters });
  },

  getPropertyDetails(filters) {
    return API.get(`/address/`, { params: filters });
  },

  async getLocationData(filters) {
    const [
      soilDetails,
      ecologicalDistrictDetails,
      propertyDetails,
      regionDetails,
    ] = await Promise.all([
      this.getSoilDetails(filters),
      this.getEcologicalDistrictDetails(filters),
      this.getPropertyDetails(filters),
      this.getRegionDetails(filters),
    ]);

    let locationData = {};
    locationData =
      soilDetails.status === 200
        ? Object.assign(locationData, soilDetails.data[0])
        : locationData;
    locationData =
      ecologicalDistrictDetails.status === 200
        ? Object.assign(locationData, ecologicalDistrictDetails.data[0])
        : locationData;
    locationData =
      propertyDetails.status === 200
        ? Object.assign(locationData, propertyDetails.data)
        : locationData;
    locationData =
      regionDetails.status === 200
        ? Object.assign(locationData, regionDetails.data)
        : locationData;

    return locationData;
  },
};

export default LocationRepsostory;
