import axios from "axios";

// Create the axios object
const repo = axios.create({
  baseURL: "/api",
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: "csrftoken",
});

repo.defaults.headers.post["access-control-allow-origin"] = "*";

export default repo;
