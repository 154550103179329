import { useState, useEffect } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import LocationRepsostory from '../../../repository/LocationRepository';
import { CircularProgress } from "@mui/material";


const AddressSearchSuggestions = ({results, onClick}) => (
  (Array.isArray(results) && results.length > 0)
    ? <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <List>
          {results.map((r, idx) => (
            <ListItem disablePadding key={idx}>
              <ListItemButton onClick={() => onClick(r)}>
                <ListItemText primary={r.address} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    : null);

const AddressSearch = ({onSelect, classNames}) => {
  const [value, setValue] = useState("");
  const [enable, setEnable] = useState(true);
  const [selected, setSelected] = useState(null);
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setResults([]);

    if (enable && value && value.length > 5) {
      const timer = setTimeout(() => {
        setIsLoading(true);
        LocationRepsostory.getPropertyDetails({search: value}).then(resp => {
          setResults(resp.data);
          setIsLoading(false);
        });
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [value, enable]);

  useEffect(() => {
    onSelect(selected);
  }, [selected, onSelect]);

  return (
    <div classNames={classNames}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Enter address..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {isLoading ? <CircularProgress size="1.2rem" color="inherit" /> : <PlaceIcon />}
            </InputAdornment>
          )
        }}
        autoComplete="off"
        onChange={(e) => {
          setEnable(true);
          setValue(e.target.value);
          if (selected && e.target.value !== selected.address) {
            setSelected(null);
          }
        }}
        value={value}
      />
      <AddressSearchSuggestions
        results={results}
        onClick={(r) => {
          setValue(r.address);
          setEnable(false);
          setSelected(r);
        }}
      />
    </div>);
};

export default AddressSearch;
