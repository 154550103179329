import { useState } from 'react';
import Step from '../Step';
import StepInformation from '../StepInformation';
import staticText from '../../../assets/data/staticText.json'
import keyBackgroundImage from '../../../assets/img/stepBackgrounds/step6.jpg';
import { StepperFooter, useStepper } from '../../providers/StepperProvider';
import { useFilter } from '../../providers/FilterProvider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Repository from '../../../repository/Repository';

const ActivationStep = () => {
  const MAX_LENGTH = 20;
  const [value, setValue] = useState(new URLSearchParams(window.location.search).get("key") || "");
  const [nextDisabled, setNextDisabled] = useState(value.length < MAX_LENGTH);
  const [error, setError] = useState("");
  const { updateFilters } = useFilter();
  const { setStepNext } = useStepper();

  const onChange = e => {
    const newValue = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, "").slice(0, MAX_LENGTH);
    setNextDisabled(newValue.length !== MAX_LENGTH);
    setValue(newValue);
    setError("");
  };

  const onNext = () => {
    const data = { key: value };
    Repository.post("/key/validate/", data).then(resp => {
      updateFilters(data);
      setStepNext();
    }).catch(e => {
      setError(
        e.response.status === 404
          ? "Invalid or expired activation key. Please try again."
          : "Something went wrong. Please try again."
      );
    });
  };

  const keyPanel = (
    <div className="p-5">
      <StepInformation
        title={staticText.steps.key.title}
        description={<p>{staticText.steps.key.description}</p>}
      />
      <div className="p-4">
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Enter activation key..."
          autoComplete="off"
          error={error.length > 0}
          helperText={error}
          onChange={onChange}
          value={value}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', py: 2, paddingRight: '10pt', paddingLeft: '10pt', minHeight: '68px' }}>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button href="/api/key/purchase">Purchase Key</Button>
        </Box>
      </div>
    </div>
  );

  return (
    <>
      <Step
        contentComponent={keyPanel}
        backgroundImage={keyBackgroundImage}
      />
      <StepperFooter nextDisabled={nextDisabled} onNext={onNext} />
    </>);
};

export default ActivationStep;
