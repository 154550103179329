import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import staticText from "../../../assets/data/staticText.json";
import forestGraphic from "../../../assets/img/habitats/1a_Forest_Section.png";
import { CircularProgress } from "@mui/material";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function PlantResultsTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  let rows = [];
  if (props.rows) {
    rows =
      rowsPerPage > 0
        ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : props.rows;
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 700,
    },
  });

  const forestDiagramInformation = (
    <div style={{ width: "100%" }}>
      <p>{staticText.steps.results.forestDiagramDescription}</p>
      <img
        src={forestGraphic}
        style={{ width: "100%" }}
        alt={staticText.steps.results.forestDiagramDescription}
      />
    </div>
  );

  return (
    <TableContainer component={Paper}>
      <Table size="small" stickyHeader aria-label="Plant table">
        <TableHead>
          <TableRow>
            <TableCell>Names</TableCell>
            <CustomWidthTooltip arrow title={forestDiagramInformation}>
              <TableCell align="right">
                Growth Form / Max Height (m) / Spacing (m) / Forest Position
              </TableCell>
            </CustomWidthTooltip>
            <TableCell align="right">Moisture Preferences</TableCell>
            <TableCell align="right">
              Tolerances (Water / Drought / Frost / Salinity)
            </TableCell>
            <TableCell align="right">Ecosystem Services</TableCell>
            <TableCell align="right">Carbon Sequestration Rate</TableCell>
            <TableCell align="right">Planting Stage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow style={{ height: 150 }}>
              <TableCell colSpan={7}>
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              </TableCell>
            </TableRow>
          )}
          {rows.length > 0 &&
            rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.growthForm}</TableCell>
                <TableCell align="right">{row.moisturePreferences}</TableCell>
                <TableCell align="right">{row.plantTolerances}</TableCell>
                <TableCell align="right">{row.ecosystemServices}</TableCell>
                <TableCell align="right">{row.carbonSequestration}</TableCell>
                <TableCell align="right">{row.plantingStage}</TableCell>
              </TableRow>
            ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={7} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              className="plant-list-pagination"
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={7}
              count={props.rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              rows={props.rows}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
