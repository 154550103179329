import { useState } from 'react';
import Step from '../Step';
import StepInformation from '../StepInformation';
import staticText from '../../../assets/data/staticText.json'
import addressBackgroundImage from '../../../assets/img/stepBackgrounds/step1.jpg';
import AddressSearch from './AddressSearch';
import { StepperFooter } from '../../providers/StepperProvider';
import { useFilter } from '../../providers/FilterProvider';


const AddressStep = () => {
  const [nextDisabled, setNextDisabled] = useState(true);
  const { updateFilters } = useFilter();

  const addressPanel = (
    <div className="p-5">
      <StepInformation
        title={staticText.steps.address.title}
        description={<p>{staticText.steps.address.description}</p>}
      />
      <div className="p-4">
        <AddressSearch onSelect={address => {
          if (address) {
            setNextDisabled(false);
            updateFilters({
              coordinates: {
                lat: address.coordinates[1],
                lng: address.coordinates[0],
              },
            });
          } else {
            setNextDisabled(true);
          }
        }}/>
      </div>
    </div>
  );

  return (
    <>
      <Step
        contentComponent={addressPanel}
        backgroundImage={addressBackgroundImage}
      />
      <StepperFooter nextDisabled={nextDisabled} />
    </>);
};

export default AddressStep;
