import { useState } from 'react';
import Step from "../Step";
import HabitatSelector from "./HabitatSelector";
import StepInformation from "../StepInformation";
import staticText from "../../../assets/data/staticText.json";
import habitatBackgroundImage from "../../../assets/img/stepBackgrounds/step3.jpg";
import { StepperFooter } from '../../providers/StepperProvider';

export default function HabitatStep(props) {
  const [nextDisabled, setNextDisabled] = useState(true);

  const habitatInfoPanel = (
    <StepInformation
      title={staticText.steps.habitat.title}
      description={<p>{staticText.steps.habitat.description}</p>}
    />
  );

  const habitatSelectionPanel = <HabitatSelector setNextDisabled={setNextDisabled} />;

  return (
    <>
      <Step
        informationComponent={habitatInfoPanel}
        selectionComponent={habitatSelectionPanel}
        backgroundImage={habitatBackgroundImage}
      />
      <StepperFooter nextDisabled={nextDisabled} />
    </>
  );
}
