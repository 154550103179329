import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Tooltip from "@mui/material/Tooltip";
import staticText from "../../../assets/data/staticText.json";
import { useFilter } from "../../providers/FilterProvider";

const WET_SOIL_DESCRIPTION = (
  <p>
    Is your ground/soil <strong>VERY WET</strong>? i.e. is it soft and squishy
    under foot with ground water at or near surface for three months of year or
    more? Your soil is considered wet if the{" "}
    <Tooltip
      arrow
      title="Topsoil is the uppermost layer of soil – usually 5-20cm of dark soil with high concentrations of organic matter. Subsoil is the layer under the topsoil and generally contains less organic matter and more of the small particles such as sand, silt and clay that characterize the mineral and geology of the location."
    >
      <strong>subsoil</strong>
    </Tooltip>{" "}
    is either very dark brown and{" "}
    <Tooltip
      arrow
      title="Peat  is an accumulation of partially decayed vegetation or organic matter, usually brown and often formed in waterlogged areas."
    >
      <strong>peat</strong>
    </Tooltip>
    -like, or grey rather than a red, brown, or yellow rusty colour?
  </p>
);

const DRY_SOIL_DESCRIPTION = (
  <p>
    Is your ground/soil <strong>VERY DRY</strong>? i.e. If your soil is very
    well drained, stony, sandy or shallow (less than one metre depth to bed
    rock, stones or other hard/artificial surface), or is it completely dry for
    three months of year or more, and not irrigated then we consider it dry.
  </p>
);

const MESIC_SOIL_DESCRIPTION = (
  <p>
    If your ground/soil does not meet either of the two previous definitions,
    we’d likely classify it as{" "}
    <strong>MOIST, or somewhere in between very wet and very dry</strong>. Your
    ground is considered moist if it has compacted soil that is fine or has a
    soft/crumbly texture. The sand, silt and clay mixture known as loam is
    considered moist in this context. Moist soils are more than one metre above
    an impervious layer, and are never water-logged nor are they completely dry
    for more than three months. If you are unsure, or if you plan to irrigate
    your site, then select this option.
  </p>
);

export default function SoilSelector({setNextDisabled}) {
  const { filters, updateFilters } = useFilter();

  const [value, setValue] = React.useState(filters.soilVariant);
  const [helperText, setHelperText] = React.useState(
    staticText.steps.soil.optionsHelperText
  );

  React.useEffect(() => {
    if (filters.soilVariant) {
      setNextDisabled(false);
    }
  }, [filters, setNextDisabled]);

  const handleRadioChange = (event) => {
    const soilVariantSelection = event.target.value;
    setValue(soilVariantSelection);
    setHelperText(" ");
    updateFilters({ soilVariant: soilVariantSelection });
    setNextDisabled(false);
  };

  return (
    <form>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">
          {staticText.steps.soil.optionsLabel}
        </FormLabel>
        <RadioGroup
          aria-label="soil-varient-selection"
          name="soil varient selection"
          value={value ?? ""}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="D"
            control={<Radio />}
            label={DRY_SOIL_DESCRIPTION}
            sx={{ paddingTop: "15px", paddingBottom: "15px" }}
          />
          <FormControlLabel
            value="W"
            control={<Radio />}
            label={WET_SOIL_DESCRIPTION}
            sx={{ paddingTop: "15px", paddingBottom: "15px" }}
          />
          <FormControlLabel
            value="M"
            control={<Radio />}
            label={MESIC_SOIL_DESCRIPTION}
            sx={{ paddingTop: "15px", paddingBottom: "15px" }}
          />
        </RadioGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </form>
  );
}
