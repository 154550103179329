import { useState } from 'react';
import { Container } from "reactstrap";
import Header from "../components/Header";
import ActivationStep from "../components/steps/activation/ActivationStep";
import AddressStep from "../components/steps/address/AddressStep";
import SoilStep from "../components/steps/soilvariant/SoilStep";
import HabitatStep from "../components/steps/habitat/HabitatStep";
import ZoneStep from "../components/steps/zone/ZoneStep";
import SummaryStep from "../components/steps/summary/SummaryStep";
import CompleteStep from "../components/steps/complete/CompleteStep";
import { StepperWizard } from "../components/providers/StepperProvider";
import { useFilter } from "../components/providers/FilterProvider";
import { Box, Typography, Modal, Button } from '@mui/material';


const ApplyPage = () => {
  const [error, setError] = useState("");
  const { submit } = useFilter();

  const onSubmit = async () => {
    try {
      await submit();
    } catch (e) {
      setError("There was a problem sending data to the API. Please try again.");
      throw e;
    }
  }

  return (
    <>
      <Container fluid className="main-container p-0">
        <Header />
        <StepperWizard>
          <ActivationStep label="Activate" tooltip="Enter your activation key" />
          <AddressStep label="Enter address" tooltip="Enter your address" />
          <SoilStep label="Choose soil" tooltip="Describe the moisture content of your soil" />
          <HabitatStep label="Choose habitat" tooltip="Specify type of landscape to be planted" />
          <ZoneStep label="Select zone" tooltip="Specify geographical detail" />
          <SummaryStep label="Summary" tooltip="Check your inputs" onSubmit={onSubmit} />
          <CompleteStep label="Complete" tooltip="Complete your application" />
        </StepperWizard>
      </Container>
      <Modal open={error.length > 0} onClose={() => setError("")}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          color: 'white',
          p: 4,
        }}>
          <Typography variant="h4" component="h2">Error</Typography>
          <Typography sx={{ mt: 2 }}>{error}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={() => setError("")}>Close</Button>
          </Box>
        </Box>
      </Modal>
    </>);
};

export default ApplyPage;
