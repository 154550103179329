import { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import SampleBackground from "../../assets/img/stepBackgrounds/step1.jpg";

export default function Step(props) {
  const [stepBackgroundImage, setStepBackground] = useState(SampleBackground);

  useEffect(
    () => setStepBackground(props.backgroundImage || SampleBackground),
    [props.backgroundImage]
  );

  return (
    <div
      className="step-container"
      style={{ backgroundImage: `url(${stepBackgroundImage})` }}
    >
      <div className="step-overlay">
        {props.contentComponent ? (
          props.contentComponent
        ) : (
          <Row style={{ height: "100%" }}>
            <Col
              lg={{ size: "4" }}
              md="5"
              sm="12"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: "50px",
                paddingLeft: "50px",
              }}
            >
              {props.informationComponent}
            </Col>
            <Col
              lg={{ size: "8" }}
              md="7"
              sm="12"
              style={{ display: "flex", alignItems: "center" }}
            >
              {props.selectionComponent}
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
}
