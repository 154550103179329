import Step from "../Step";
import SummaryContent from "./SummaryContent";
import StepInformation from "../StepInformation";
import staticText from "../../../assets/data/staticText.json";
import summaryBackgroundImage from "../../../assets/img/stepBackgrounds/step5.jpg";
import { StepperFooter } from '../../providers/StepperProvider';


export default function SummaryStep({ onSubmit }) {
  const summaryInfoPanel = (
    <StepInformation
      title={staticText.steps.summary.title}
      description={<p>{staticText.steps.summary.description}</p>}
    />
  );

  const summaryContent = <SummaryContent />;

  return (
    <>
      <Step
        informationComponent={summaryInfoPanel}
        selectionComponent={summaryContent}
        backgroundImage={summaryBackgroundImage}
      />
      <StepperFooter onSubmit={onSubmit} />
    </>
  );
}
