import Step from "../Step";
import StepInformation from "../StepInformation";
import completeBackgroundImage from "../../../assets/img/stepBackgrounds/step6.jpg";
import staticText from "../../../assets/data/staticText.json";
import { StepperFooter } from "../../providers/StepperProvider";

export default function CompleteStep() {
  const completeInfoPanel = (
    <StepInformation
      title={staticText.steps.complete.title}
      description={<p>{staticText.steps.complete.description}</p>}
    />
  );

  return (
    <>
      <Step
        informationComponent={completeInfoPanel}
        backgroundImage={completeBackgroundImage}
      />
      <StepperFooter />
    </>
  );
};
