import Repository from "./Repository";

const PlantRepository = {
  getFilteredPlants(filters) {
    return Repository.get(`/plants/`, { params: filters });
  },

  getPlantsCSV(filters) {
    return Repository.get("/download/csv/", { params: filters });
  },

  getPlantsPDF(filters) {
    return Repository.get("/download/pdf/", {
      params: filters,
      responseType: "arraybuffer",
    });
  },
};

export default PlantRepository;
