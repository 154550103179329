import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SummaryTable from "./SummaryTable";
import LocationRepository from "../../../repository/LocationRepository";
import { useFilter } from "../../providers/FilterProvider";

export default function SummaryContent() {
  const [expanded, setExpanded] = React.useState(null);
  const [locationDetails, setLocationDetails] = React.useState({});
  const { filters } = useFilter();

  const getLocationDetails = () => {
    LocationRepository.getLocationData(filters).then((result) => {
      setLocationDetails(result);
    });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    !Object.keys(locationDetails).length && getLocationDetails();
  });

  function createData(name, value) {
    return { name, value };
  }

  const locationData = [
    createData(
      "Geographical Coordinates (latitude, longitude)",
      `(${filters.coordinates.lat}, ${filters.coordinates.lng})`
    ),
    createData("Ecological Region", locationDetails.ecological_region || ""),
    createData(
      "Ecological District",
      locationDetails.ecological_district || ""
    ),
    createData("Property Name", locationDetails.full_address || ""),
  ];

  const soilData = [
    createData(
      "Soil Order",
      `${locationDetails.soil_name} (${locationDetails.soil_code})` || ""
    ),
    createData("Soil Variant", filters.soilVariant),
  ];

  const siteData = [
    createData("Habitat", filters.habitat.name ?? ""),
    createData(
      "Zone Name",
      (filters.zone && filters.zone.name) ?? ""
    ),
    createData(
      "Zone Variant",
      (filters.zone && filters.zone.variant) ?? ""
    ),
    createData(
      "Zone Refined Variant",
      (filters.zone && filters.zone.refined_variant) ?? ""
    ),
  ];

  const regionInformation = () => {
    if (locationDetails.in_chch) {
      return (
        <Typography variant="body2">
          Your location falls within the ecosystem type covered by the
          Christchurch Council ecosystem maps - further information can be
          obtained from{" "}
          <a
            href="https://ccc.govt.nz/environment/land/ecosystem-map"
            target="blank"
          >
            Ōtautahi/Christchurch ecosystems map
          </a>
          .
        </Typography>
      );
    } else if (locationDetails.in_auckland) {
      return (
        <Typography variant="body2">
          "Your location falls within the ecosystem type covered by the Auckland
          Council Tiaki Tāmaki Makaurau Conservation map - further information
          can be obtained from{" "}
          <a
            href="https://www.tiakitamakimakaurau.nz/conservation-map/"
            target="blank"
          >
            tiaki Tāmaki Makaurau conservation Auckland
          </a>
        </Typography>
      );
    }
  };

  return (
    <div className="summary-content">
      <Typography mb={2} mt={2}>
        Please review your choices presented below:{" "}
      </Typography>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>1. Location Data</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SummaryTable rows={locationData} />
          <Box ml={2} mt={2}>
            {regionInformation()}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>2. Soil Properties</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SummaryTable rows={soilData} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>3. Project Site Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SummaryTable rows={siteData} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
