import { Container } from "reactstrap";
import Header from "../components/Header";
import LocationStep from "../components/steps/location/LocationStep";
import SoilStep from "../components/steps/soilvariant/SoilStep";
import HabitatStep from "../components/steps/habitat/HabitatStep";
import ZoneStep from "../components/steps/zone/ZoneStep";
import SummaryStep from "../components/steps/summary/SummaryStep";
import ResultsStep from "../components/steps/results/ResultsStep";
import { StepperWizard } from "../components/providers/StepperProvider";

const MainPage = () => {
  return (
    <Container fluid className="main-container p-0">
      <Header />
      <StepperWizard>
        <LocationStep label="Select location" tooltip="Click on a location on the map" />
        <SoilStep label="Choose soil" tooltip="Describe the moisture content of your soil" />
        <HabitatStep label="Choose habitat" tooltip="Specify type of landscape to be planted" />
        <ZoneStep label="Select zone" tooltip="Specify geographical detail" />
        <SummaryStep label="Summary" tooltip="Check your inputs" />
        <ResultsStep label="Results" tooltip="List of plant species and user guide" />
      </StepperWizard>
    </Container>);
};

export default MainPage;
