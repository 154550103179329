import { createContext, useState, useContext } from 'react';
import Repository from '../../repository/Repository';

const FilterContext = createContext(null);

const FilterProvider = ({children}) => {
  const [filters, setFilters] = useState({});

  const resetFilters = () => setFilters({});

  const updateFilters = newFilters => setFilters(oldFilters => ({...oldFilters, ...newFilters}));

  const submit = () => Repository.post(
    "/questionnaire/",
    {
      location: `SRID=4326;POINT (${filters.coordinates.lng} ${filters.coordinates.lat})`,
      soil_variant: filters.soilVariant,
      zone: filters.zone.id,
      key: filters.key,
    },
  );

  const value = {
    filters,
    setFilters,
    resetFilters,
    updateFilters,
    submit,
  };

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

const useFilter = () => useContext(FilterContext);

export {
  FilterProvider,
  useFilter,
};
