import TopNav from "./TopNav";
import logo from "../assets/img/logo.png";

export default function Header() {
  return (
    <div className="d-flex justify-content-between top-header">
      <a href="https://www.b4c3.com/">
        <img
          className="header-logo"
          src={logo}
          alt="Biosphere Capital Limited"
        />
      </a>
      <TopNav />
    </div>
  );
}
