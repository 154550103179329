import { lazy, Suspense } from 'react';
import { CircularProgress, Stack } from "@mui/material";

export const HabitatSVG = ({ name, ...rest }) => {
  const Diagram = lazy(() => import(`../diagrams/${name}`));
  const loadingComponent = (
    <Stack alignItems="center">
      <CircularProgress />
    </Stack>
  );
  return (
    <Suspense fallback={loadingComponent}> {/* experiemental in React 17. TODO: upgrade to 18 */}
      <Diagram {...rest} />
    </Suspense>);
};
