import { useState } from "react";
import Step from "../Step";
import LocationSelectorMap from "./Map";
import StepInformation from "../StepInformation";
import staticText from "../../../assets/data/staticText.json";
import locationBackgroundImage from "../../../assets/img/stepBackgrounds/step1.jpg";
import { StepperFooter } from '../../providers/StepperProvider';

export default function LocationStep(props) {
  const [nextDisabled, setNextDisabled] = useState(true);

  const locationInfoPanel = (
    <StepInformation
      title={staticText.steps.location.title}
      description={<p>{staticText.steps.location.description}</p>}
    />
  );

  const locationSelectionPanel = <LocationSelectorMap setNextDisabled={setNextDisabled} />;

  return (
    <>
      <Step
        informationComponent={locationInfoPanel}
        selectionComponent={locationSelectionPanel}
        backgroundImage={locationBackgroundImage}
      />
      <StepperFooter nextDisabled={nextDisabled} />
    </>
  );
}
