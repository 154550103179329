import { useEffect, useState } from "react";
import SiteRepository from "../../../repository/SiteRepository";
import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import staticText from "../../../assets/data/staticText.json";
import { HabitatSVG } from "../HabitatSVG";
import { useFilter } from '../../providers/FilterProvider';

export default function HabitatSelector({setNextDisabled}) {
  const { filters, updateFilters } = useFilter();
  const [habitats, setHabitats] = useState([]);
  const [habitatsMap, setHabitatsMap] = useState({});
  const [value, setValue] = React.useState(
    filters.habitat && filters.habitat.id
  );
  const [selectedHabitat, setSelectedHabitat] = React.useState({});
  const [imageValue, setImageValue] = React.useState(
    filters.habitatImage
  );

  const getHabitats = () => {
    SiteRepository.getHabitats().then((response) => {
      if (response.status === 200) {
        // Create a mapping from the habitat id to the object
        let habitatsJson = {};
        response.data.forEach((habitat) => {
          habitatsJson[habitat.id] = habitat;
        });
        setHabitats(response.data);
        setHabitatsMap(habitatsJson);
      }
    });
  };

  useEffect(() => {
    // Retrieves the habitats from the api if they are not loaded already
    habitats.length === 0 && getHabitats();

    // Sets the selected habitat if its already set in filters
    if (filters.habitat && filters.habitat.id) {
      setSelectedHabitat(habitatsMap[filters.habitat.id]);
    }

    // If both the habitat and the image is selected, then enable the next step
    if (filters.habitat && filters.habitatImage) {
      setNextDisabled(false);
    }
  }, [habitats.length, setNextDisabled, habitatsMap, filters]);

  const setHabitatImage = (imageId) => {
    // Sets the selected image radio, updates filter state for image and enable the next button
    setImageValue(imageId);
    updateFilters({ habitatImage: imageId });
    updateFilters({ zone: null });
    setNextDisabled(false);
  };

  const handleRadioChange = (event) => {
    // Retrieve and set the value of the radio button
    const habitatSelection = event.target.value;
    setValue(habitatSelection);
    setHabitatImage(null);

    // Set the image default selection if there is only one image variation available
    const habitatObject = habitatsMap[habitatSelection];
    setSelectedHabitat(habitatObject);
    if (habitatObject.images.length === 1) {
      setHabitatImage(habitatObject.images[0].id);
    } else {
      setNextDisabled(true);
    }

    // Update the filters for the selected habitat
    updateFilters({
      habitat: { id: habitatObject.id, name: habitatObject.name },
    });
  };

  const handleImageRadioChange = (event) => {
    const habitatImageSelection = event.target.value;
    setHabitatImage(habitatImageSelection);
  };

  const getImageLabel = (image) => {
    return (
      <div>
        <p>{image.name}</p>
        <HabitatSVG
          name={image.image_filename}
          style={{ height: "auto", width: "300px" }}
        />
      </div>
    );
  };

  return (
    <div className="d-flex align-top justify-space-between">
      <form>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel component="legend">
            {staticText.steps.habitat.optionsLabel}
          </FormLabel>
          <RadioGroup
            aria-label="habitat-selection"
            name="habitat selection"
            value={value ?? ""}
            onChange={handleRadioChange}
          >
            {habitats &&
              Array.isArray(habitats) &&
              habitats.map((habitat) => (
                <FormControlLabel
                  key={habitat.id}
                  value={habitat.id}
                  control={<Radio />}
                  label={habitat.name}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </form>
      <form>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel component="legend">
            {staticText.steps.habitat.imageOptionsLabel}
          </FormLabel>
          <RadioGroup
            aria-label="habitat-image-selection"
            name="habitat image selection"
            value={imageValue ?? ""}
            onChange={handleImageRadioChange}
          >
            {selectedHabitat &&
              selectedHabitat.images &&
              Array.isArray(selectedHabitat.images) &&
              selectedHabitat.images.map((image) => (
                <FormControlLabel
                  key={image.id}
                  value={image.id}
                  control={<Radio />}
                  label={getImageLabel(image)}
                  sx={{ paddingTop: "15px", paddingBottom: "15px" }}
                />
              ))}
            {(!selectedHabitat ||
              (selectedHabitat.images &&
                selectedHabitat.images.length === 0)) && (
              <p>No images available.</p>
            )}
          </RadioGroup>
        </FormControl>
      </form>
    </div>
  );
}
