import { useEffect, useState } from "react";
import SiteRepository from "../../../repository/SiteRepository";
import { useFilter } from "../../providers/FilterProvider";
import { HabitatSVG } from "../HabitatSVG";

export default function ZoneSelector({setNextDisabled}) {
  const [habitatImageObject, setHabitatImageObject] = useState({});
  const [segmentMapping, setSegmentMapping] = useState({});
  const [selectedZoneSegment, setZoneSegment] = useState(null);
  const { filters, updateFilters } = useFilter();

  const setZoneOrRedirect = (zone) => {
    const redirectHabitat = zone && zone.redirect_habitat;

    // If there is a redirect habitat set then redirect otherwise set the zone as state
    const newFilterState = redirectHabitat
      ? { habitat: { id: redirectHabitat.id, name: redirectHabitat.name } }
      : { zone: zone };

    updateFilters(newFilterState);
    setNextDisabled(false);
  };

  useEffect(() => {
    const getHabitatImage = () => {
      SiteRepository.getHabitatImage(filters.habitatImage).then(
        (response) => {
          if (response.status === 200) {
            const imageData = response.data;
            setHabitatImageObject(imageData);
          }
        }
      );
    };

    const setInitialZone = () => {
      if (filters.zone) {
        const zone = filters.zone;
        const zoneSegment = document.querySelectorAll(
          `.zone-selector-svg svg path[inkscapelabel="${zone.related_svg_segment}"]`
        )[0];

        // If the previously selected zone segment is located in the diagram then highlight it
        if (zoneSegment) {
          setZoneSegment(zoneSegment);
          zoneSegment.style.fill = "#eeeeee";
          zoneSegment.style["fill-opacity"] = 0.5;
          setNextDisabled(false);
        }
      }
    };

    const getZones = () => {
      SiteRepository.getZones().then((response) => {
        if (response.status === 200) {
          const zoneData = response.data;
          let newSegmentMapping = {};
          zoneData.forEach((zone) => {
            newSegmentMapping[zone.related_svg_segment] = zone;
          });
          setSegmentMapping(newSegmentMapping);
          setInitialZone();
        }
      });
    };

    // Retrieves the habitat image from the api if it's not loaded already
    Object.keys(habitatImageObject).length === 0 && getHabitatImage();

    // Retrieves the habitat image from the api if it's not loaded already
    Object.keys(segmentMapping).length === 0 && getZones();
  }, [habitatImageObject, segmentMapping, setNextDisabled, filters]);

  const selectZone = (element) => {
    if (
      ["path", "rect"].includes(element.tagName) &&
      element.attributes.inkscapelabel &&
      element.attributes.inkscapelabel.nodeValue
    ) {
      if (selectedZoneSegment) {
        selectedZoneSegment.style["fill-opacity"] = 0;
      }

      setZoneSegment(element);
      element.style.fill = "#eeeeee";
      element.style["fill-opacity"] = 0.5;

      const zone = segmentMapping[element.attributes.inkscapelabel.nodeValue];

      setZoneOrRedirect(zone);
    }
  };

  const showTooltip = (evt) => {
    const element = evt.target;
    if (
      ["path", "rect"].includes(element.tagName) &&
      element.attributes.inkscapelabel &&
      element.attributes.inkscapelabel.nodeValue
    ) {
      const zone = segmentMapping[element.attributes.inkscapelabel.nodeValue];

      if (zone) {
        let tooltip = document.getElementById("svg-tooltip");
        tooltip.innerHTML = zone.tooltip_display_text;
        tooltip.style.display = "block";
        tooltip.style.left = evt.pageX + 10 + "px";
        tooltip.style.top = evt.pageY + 10 + "px";
        tooltip.style.opacity = 1;
      }
    }
  };

  const hideTooltip = () => {
    var tooltip = document.getElementById("svg-tooltip");
    tooltip.style.display = "none";
  };

  return Object.keys(habitatImageObject).length > 0 ? (
    <div style={{ width: "100%" }}>
      <p>{habitatImageObject.name}</p>
      <div className="zone-selector-svg">
        <div
          id="svg-tooltip"
          className="MuiTooltip-tooltip MuiTooltip-tooltipArrow MuiTooltip-tooltipPlacementBottom"
          display="none"
          style={{ position: "absolute", display: "none" }}
        ></div>
        <HabitatSVG
          onClick={(event) => selectZone(event.target)}
          onMouseMove={(event) => showTooltip(event)}
          onMouseOut={() => hideTooltip()}
          name={habitatImageObject.image_filename}
          style={{ height: "auto", width: "100%" }}
        />
      </div>
    </div>
  ) : (
    <div></div>
  );
}
