import { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import LocationRepository from "../../../repository/LocationRepository";
import { useFilter } from "../../providers/FilterProvider";

const NZ_BOUNDS = [
  [-47.204642, 165.344238],
  [-34.307144, 179.824219],
];

function LocationMarker({setNextDisabled}) {
  const [position, setPosition] = useState(null);
  const { filters, updateFilters } = useFilter();

  const map = useMapEvents({
    click(e) {
      const newPosition = e.latlng;
      setPosition(newPosition);
      updateFilters({ coordinates: newPosition });
      setNextDisabled(false);
    },
  });

  map.whenReady(() => {
    const savedCoordinates = filters.coordinates;
    if (!position && savedCoordinates) {
      setPosition(savedCoordinates);
      setNextDisabled(false);
      map.flyTo(savedCoordinates, 9);
    }
  });

  return position === null ? null : <Marker position={position} />;
}

const fitNZBounds = (map) => {
  map.fitBounds(NZ_BOUNDS);
};

function LocationDetailsDisplay(props) {
  const [locationDetails, setLocationDetails] = useState({});
  const { filters } = useFilter();

  useEffect(() => {
    if (filters.coordinates) {
      LocationRepository.getLocationData(filters).then((result) => {
        setLocationDetails(result);
      });
    }
  }, [filters]);

  const savedCoordinates = filters.coordinates;

  if (savedCoordinates) {
    const soilString = `${locationDetails.soil_name} (${locationDetails.soil_code})`;
    return (
      <div className="coordinates-display">
        <strong>Latitude:</strong> {savedCoordinates.lat} <br />
        <strong>Longitude:</strong> {savedCoordinates.lng} <br />
        <strong>Address:</strong> {locationDetails.full_address} <br />
        <strong>Ecological Region:</strong> {locationDetails.ecological_region}{" "}
        <br />
        <strong>Ecological District:</strong>{" "}
        {locationDetails.ecological_district} <br />
        <strong>Soil Order:</strong>{" "}
        {locationDetails.soil_name ? soilString : ""}
      </div>
    );
  }

  return null;
}

export default function Map(props) {
  return (
    <div className="map-container">
      <LocationDetailsDisplay {...props} />
      <MapContainer scrollWheelZoom={true} whenCreated={fitNZBounds}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker {...props} />
      </MapContainer>
    </div>
  );
}
