import { useState } from 'react';
import Step from "../Step";
import SoilSelector from "./SoilSelector";
import StepInformation from "../StepInformation";
import staticText from "../../../assets/data/staticText.json";
import soilBackgroundImage from "../../../assets/img/stepBackgrounds/step2.jpg";
import { StepperFooter } from '../../providers/StepperProvider';


export default function SoilVariantStep(props) {
  const [nextDisabled, setNextDisabled] = useState(true);

  const SOIL_DESCRIPTION = (
    <p>
      From your site location, we use{" "}
      <a
        href="https://soils.landcareresearch.co.nz/describing-soils/nzsc/"
        target="blank"
      >
        New Zealand Soil Classification
      </a>{" "}
      data and additional{" "}
      <a href="https://www.landcareresearch.co.nz" target="blank">
        Manaaki Whenua Landcare Research
      </a>{" "}
      data to determine overall soil type. However we also need to know as much
      as possible about the moisture content of the soil at your planting site
      location.
      <br />
      <br />
      Please select an option from the choices listed to the right:
    </p>
  );

  const soilVarientInfoPanel = (
    <StepInformation
      title={staticText.steps.soil.title}
      description={SOIL_DESCRIPTION}
    />
  );

  const soilVarientSelectionPanel = (
    <div className="p-5">
      <SoilSelector setNextDisabled={setNextDisabled} />
    </div>
  );

  return (
    <>
      <Step
        informationComponent={soilVarientInfoPanel}
        selectionComponent={soilVarientSelectionPanel}
        backgroundImage={soilBackgroundImage}
      />
      <StepperFooter nextDisabled={nextDisabled} />
    </>
  );
}
