import { createContext, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { useFilter } from './FilterProvider';

const StepContext = createContext(null);

const StepperWizard = ({children}) => {
  const [step, setStep] = useState(0);

  const isStep = n => (0 <= n && n < children.length);
  const setStepNext = () => setStep(n => isStep(n + 1) ? n + 1 : n);
  const setStepBack = () => setStep(n => isStep(n - 1) ? n - 1 : n);

  const value =  {
      step,
      setStep,
      setStepNext,
      setStepBack,
      isStep,
  };

  return (
    <StepContext.Provider value={value}>
      <Box sx={{ width: '100%', height: '100%', display: "flex", flexDirection: "column", overflow: "hidden" }}>
        <Stepper activeStep={step} sx={{ paddingRight: '3vw', paddingLeft: '3vw', marginBottom: '2vw' }}>
          {children.map(child => (
            <Tooltip title={child.props.tooltip} key={child.props.label}>
              <Step key={child.props.label}>
                <StepLabel>{child.props.label}</StepLabel>
              </Step>
            </Tooltip>)
          )}
        </Stepper>
        {children[step]}
      </Box>
    </StepContext.Provider>
  );
};

const useStepper = () => useContext(StepContext);

const StepperFooter = ({nextDisabled, backDisabled, onBack = null, onNext = null, onSubmit = async () => {}}) => {
  const { step, isStep, setStepNext, setStepBack, setStep } = useStepper();
  const { resetFilters } = useFilter();
  const isSubmit = !isStep(step + 2);

  const _onBack = () => {
    if (isStep(step + 1)) {
      setStepBack();
    } else {
      resetFilters();
      setStep(0);
    }
  };

  const _onNext = () => {
    setStepNext();
  };

  const _onSubmit = async () => {
    try {
      await onSubmit();
    } catch {
      return;
    }

    if (onNext) {
      onNext();
    } else {
      _onNext();
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, pb: 2, paddingRight: '3vw', paddingLeft: '3vw', minHeight: '68px' }}>
      {isStep(step - 1) &&
        <Button
          onClick={onBack ?? _onBack}
          disabled={backDisabled}
        >
          {isStep(step + 1) ? "Back" : "Reset"}
        </Button>
      }
      <Box sx={{ flex: '1 1 auto' }} />
      {isStep(step + 1) &&
        <Button
          onClick={isSubmit ? _onSubmit : (onNext ?? _onNext)}
          disabled={nextDisabled}
        >
          {isSubmit ? "Submit" : "Next"}
        </Button>
      }
    </Box>);
};

export {
  StepperWizard,
  StepperFooter,
  useStepper,
};
